
// import { SwapOutlined } from '@ant-design/icons-vue'
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import createVersion from './components/createVersion.vue'
import { getVersionList, publickVersion, deleteVersion } from '@/api/index'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '版本列表'
  },
  {
    id: 2,
    btnName: '新建版本'
  }
]

interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '客户端',
    dataIndex: 'client',
    key: 'client'
  },
  {
    title: '版本号',
    dataIndex: 'version',
    key: 'version'
  },
  {
    title: '版本模型',
    dataIndex: 'state',
    key: 'state',
    slots: { customRender: 'state' }
  },
  {
    title: '更新日期',
    dataIndex: 'add_time',
    key: 'add_time'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]

export default defineComponent({
  name: 'EditionManger',
  components: {
    mangerPageHeader,
    createVersion
    // SwapOutlined
  },
  setup () {
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination },
      version: '',
      client_id: 7
    })
    // 获取版本列表
    const fetchVersionList = (isDelete?: boolean) => {
      getVersionList({
        client_id: tableData.client_id,
        version: tableData.version.trim(),
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchVersionList()
        }
      })
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchVersionList)
    const changeVersion = (value: number) => {
      tableData.client_id = value
      fetchVersionList()
    }
    const fetchPublicVersion = (id: number) => {
      publickVersion({
        id,
        issue: true
      }).then(() => {
        message.success('发布成功')
        fetchVersionList()
      })
    }
    // 发布
    const publishVersion = (id: number) => {
      showConfirm({
        content: '此操作不可逆,确定要发布该版本吗?',
        callBack: () => {
          fetchPublicVersion(id)
        }
      })
    }
    const fetchDeleteList = (id: number) => {
      deleteVersion({
        id
      }).then(() => {
        message.success('删除成功')
        fetchVersionList(true)
      })
    }
    const deleteList = (id: number) => {
      showConfirm({
        content: '此操作不可逆,确定要删除该版本吗?',
        callBack: () => {
          fetchDeleteList(id)
        }
      })
    }
    const router = useRouter()
    // 跳转编辑页面
    const goEditVersion = (id: number) => {
      router.push({
        name: 'createVersion',
        params: { id }
      })
    }
    // 下载
    const downFile = (url: string) => {
      if (url) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.src = url
        document.body.appendChild(iframe)
      } else {
        message.error('下载地址为空')
      }
    }
    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetchVersionList()
    }
    const getState = (state: number) => {
      return state === 0 ? '开发版' : '正式版'
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchVersionList()
    }
    onMounted(() => {
      fetchVersionList()
    })
    return {
      btnList,
      ...toRefs(tableData),
      changeIndex,
      btnIndex,
      publishVersion,
      goEditVersion,
      fetchVersionList,
      changeVersion,
      deleteList,
      downFile,
      changePage,
      getState,
      fetchSearch
    }
  }
})
